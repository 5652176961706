<template>
    <v-dialog v-model="$usuario.showModalLogin" max-width="720" scrollable @click:outside="changeShow()" :persistent="$usuario.showModalLoginPersist" :fullscreen="991 > $config.responsive() || $config.mobile()">
        <v-card v-if="!$axios.getLocal('session') || !$axios.getLocal('session').data.cpf">
            <v-icon @click="$usuario.showModalLogin = false" class="fechar" v-if="!$usuario.showModalLoginPersist">mdi-close mdi-color-black</v-icon>
            <v-card-title>
                <v-row>
                    <v-col cols="12" class="mb-2">
                        <h2>{{$t_('login_cadastro')}}</h2>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text v-if="login.loginComum == false">
                <v-row v-show="!$usuario.showModalLogin_cadastro">

                    <v-col cols="12" class="text-center" v-for="(item, index) in $usuario.logins" :key="index">
                        <v-btn block height="60px" elevation="5" :color="item.cor" @click="item.social == false ? (login.loginComum = true, login.item = item) : $usuario.loginSocial(item)">
                            <img :src="item.icone" :width="item.tipo == 'apple' ? '45' : '30'" class="mr-3"
                                loading="lazy" />
                            <div :style="item.cor != '#ffffff' ? 'color: white' : ''" v-html="$t_('entrar_login') + ' ' + $util.removeHTML(item.dsc) "></div>
                        </v-btn>
                    </v-col>

                    <v-col cols="12">
                        <v-btn block height="50px" elevation="5" :color="$config.dados.corprimaria"
                            @click="$usuario.showModalLoginAgencia = true" style="color: white">
                            <span v-once class="font-weight-bold">{{$t_('CADASTRE_AGENCIA')}}</span>
                        </v-btn>
                    </v-col>

                    <v-col cols="12"  class="text-center">
                        <v-btn class="controlarfont" text elevation="0" @click="login.loginComum = true, login.esqueciSenha = true, $config.recaptchaModal = true" width="320px" min-width="300px">
                            <span class="text text-decoration-underline txt-esqueci-senha font-weight-bold grey--text">{{ $t_('esqueci_senha') }}</span>
                        </v-btn>
                    </v-col>

                    <v-col cols="12">
                        <v-btn block text elevation="0" @click="$usuario.showModalLogin_cadastro = true">
                            <span
                                class="txt-primeiro-acesso text-decoration-underline font-weight-bold blue--text">{{$t_('cadastre_email')}}</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-show="$usuario.showModalLogin_cadastro">
                    <v-form autocomplete="off">
                        <v-row align="center" class="my-3">

                            <v-col cols="12" sm="6" class="py-0" v-if="!login.loginAgencia">
                                <label required>{{ $t_('nome') }}</label>
                                <v-text-field class="mt-3" v-model="$usuario.cadastro.nome" type="text" dense outlined
                                    tabindex="1" :placeholder="$t_('ph_nome')"
                                    :error-messages="$usuario.cadastro.error.nome"
                                    @input="$usuario.cadastro.error.nome = []"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" v-if="!login.loginAgencia">
                                <label required> {{ $t_('data_nascimento') }} </label>
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mt-3" v-model="$usuario.cadastro.dt_nascimento" dense outlined
                                            type="tel" tabindex="2" :placeholder="placeholderData" v-mask="['XX/XX/XXXX']"
                                            :error-messages="$usuario.cadastro.error.dt_nascimento"
                                            @input="$usuario.cadastro.error.dt_nascimento = []"
                                            prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker hide-details v-model="dt_nascimento" :color="$config.dados.corsecundaria" :locale="$axios.getLocal('lang').data"
                                        :header-color="$config.dados.corprimaria" :active-picker.sync="activePicker" width="290" :landscape="!$config.mobile()"
                                        @change="save" @click:date="save"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <template v-if="login.loginAgencia">
                                <v-col cols="12" md="6" v-if="login.loginAgencia">
                                    <label required>{{ 'Razão social' }}</label>
                                    <v-text-field v-model="$usuario.cadastro.empresa_razao" key="razao_social" type="text"
                                        dense outlined hide-details class="mt-3"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6" v-if="login.loginAgencia">
                                    <label required>{{ 'Nome Fantasia' }}</label>
                                    <v-text-field v-model="$usuario.cadastro.empresa_fantasia" type="text"
                                        dense outlined hide-details  class="mt-3" ></v-text-field>
                                </v-col>

                            </template>

                            <v-col cols="12" sm="6" class="py-0" v-if="!$axios.getLocal('token')">
                                <label required>{{ $t_('email') }}</label>
                                <v-text-field class="mt-3" v-model="$usuario.cadastro.email" type="text" dense outlined
                                    :placeholder="$t_('ph_email')" tabindex="3" autocomplete="false"
                                    :error-messages="$usuario.cadastro.error.email"
                                    @input="$usuario.cadastro.error.email = []"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" class="py-0" v-if="!$axios.getLocal('token')">
                                <label required>{{ $t_('confirmar_email') }}</label>
                                <v-text-field class="mt-3" v-model="$usuario.cadastro.email2" type="text" dense outlined
                                    :placeholder="$t_('ph_email')" tabindex="4" autocomplete="false"
                                    :error-messages="$usuario.cadastro.error.email2"
                                    @input="$usuario.cadastro.error.email2 = []"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" class="py-0" v-if="!$axios.getLocal('token')">
                                <label required>{{ $t_('senha') }}</label>
                                <v-tooltip bottom max-width="300">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="primary" dark v-bind="attrs" v-on="on"
                                            style="cursor: help; position: relative; margin-left: 5px; width: 17px; height: 17px">
                                            mdi-information mdi-color-gray
                                        </v-icon>
                                    </template>
                                    <span v-once>{{$t_('tip_senha')}}</span>
                                </v-tooltip>
                                <v-text-field class="mt-3" v-model="$usuario.cadastro.senha" dense outlined
                                    :type="show1 ? 'text' : 'password'"
                                    :append-icon="show1 ? 'mdi-eye mdi-color-gray' : 'mdi-eye-off mdi-color-gray'"
                                    @click:append="show1 = !show1" placeholder="*******"
                                    :error-messages="$usuario.cadastro.error.senha"
                                    @input="$usuario.cadastro.error.senha = []" tabindex="5">
                                </v-text-field>
                                <v-progress-linear v-if="$usuario.cadastro.senha != ''" v-model="chekingPassword"
                                    :color="chekingPassword < 60 ? 'red' : chekingPassword < 90 ? 'orange' : 'green'"
                                    height="4" style="position: relative; top: -26px; ">
                                </v-progress-linear>
                            </v-col>

                            <v-col cols="12" sm="6" class="py-0" style="margin-top: 1px" v-if="!$axios.getLocal('token')">
                                <label required>{{ $t_('confirmar_senha') }}</label>
                                <v-text-field class="mt-3" v-model="$usuario.cadastro.senha2" dense outlined
                                    :type="show2 ? 'text' : 'password'"
                                    :append-icon="show2 ? 'mdi-eye mdi-color-gray' : 'mdi-eye-off mdi-color-gray'"
                                    @click:append="show2 = !show2" placeholder="*******" tabindex="6"
                                    :error-messages="$usuario.cadastro.error.senha2"
                                    @input="$usuario.cadastro.error.senha2 = []"></v-text-field>
                                <v-progress-linear v-if="$usuario.cadastro.senha != ''" :value="0" color="white"
                                    height="5" style="position: relative; top: -4px;"></v-progress-linear>
                            </v-col>
                            <v-col cols="12" md="6" v-if="login.loginAgencia">
                                <label required>CNPJ</label>
                                <v-text-field ref="cnpj" v-model="$usuario.cadastro.empresa_cnpj" type="tel" dense outlined hide-details class="mt-3" tabindex="8"
                                    v-mask="'##.###.###/####-##'"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" class="py-0" v-if="login.loginAgencia">
                                <label required>{{ $t_('TELEFONE') }}</label>
                                <VuePhoneNumberInput        
                                    v-model="$usuario.cadastro.telefone"
                                    type="text"
                                    dense
                                    outlined
                                    hide-details
                                    key="telefone"
                                    class="mt-3"
                                    tabindex="10"
                                    :no-use-browser-locale="false"
                                    :error="errorNumber"
                                    @update="updateCellPhoneNumber"
                                    :translations="phoneInputLang"
                                    :default-country-code="$lang_.getLang().telefone"
                                    :preferred-countries="['BR', 'US', 'ES']"
                                ></VuePhoneNumberInput>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0" v-if="!login.loginAgencia">
                                <label :required="!$usuario.cadastro.passaporte">{{ $t_('CPF') }}</label>
                                <v-text-field @input="$usuario.cadastro.passaporte = null" v-model="$usuario.cadastro.cpf" type="text" dense outlined
                                    v-mask="'###.###.###-##'" class="mt-3" tabindex="9"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" class="py-0" v-if="!login.loginAgencia">
                                <label :required="!$usuario.cadastro.cpf">{{ $t_('passaporte') }}</label>
                                <v-text-field @input="$usuario.cadastro.cpf = null" v-model="$usuario.cadastro.passaporte" type="text" dense outlined
                                    class="mt-3" tabindex="10"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" class="py-0 mb-6" v-if="!login.loginAgencia">
                                <label required>{{ $t_('celular') }}</label>
                                <VuePhoneNumberInput        
                                    v-model="$usuario.cadastro.celular"
                                    type="text"
                                    dense
                                    outlined
                                    hide-details
                                    class="mt-3"
                                    tabindex="11"
                                    :no-use-browser-locale="false"
                                    :error="errorNumber"
                                    @update="updatePhoneNumber"
                                    :translations="phoneInputLang"
                                    :default-country-code="$lang_.getLang().celular"
                                    :preferred-countries="['BR', 'US', 'ES']"
                                ></VuePhoneNumberInput>
                            </v-col>

                            <template v-if="$config.dados.editarEndereco">
                                <v-col cols="12">

                                    <v-divider class="mb-6"></v-divider>
                    
                                    <h2 class="perfil-text01 mb-5">{{$t_('endereco')}}</h2>

                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <label required>{{$t_('cep')}}</label>
                                            <v-text-field v-mask="'########'" v-model="$usuario.cadastro.end_cep" type="tel" dense outlined hide-details class="mt-3" tabindex="9"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <label required>{{$t_('pais')}}</label>
                                            <v-text-field v-model="$usuario.cadastro.end_pais" type="text" dense outlined hide-details class="mt-3" tabindex="10"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <label required>{{$t_('estado')}}</label>
                                            <v-text-field v-model="$usuario.cadastro.end_estado" type="text" dense outlined hide-details class="mt-3" tabindex="10"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <label required>{{$t_('cidade')}}</label>
                                            <v-text-field v-model="$usuario.cadastro.end_cidade" type="text" dense outlined hide-details class="mt-3" tabindex="10"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <label required>{{$t_('bairro')}}</label>
                                            <v-text-field v-model="$usuario.cadastro.end_bairro" type="text" dense outlined hide-details class="mt-3" tabindex="13"></v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="4">
                                            <label required>{{$t_('numero')}}</label>
                                            <v-text-field v-model="$usuario.cadastro.end_numero" v-mask="'########'" type="tel" dense outlined hide-details class="mt-3" tabindex="14"></v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="4">
                                            <label required>{{$t_('logradouro')}}</label>
                                            <v-text-field v-model="$usuario.cadastro.end_logradouro" type="text" dense outlined hide-details class="mt-3" tabindex="15"></v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="4">
                                            <label>{{$t_('complemento')}}</label>
                                            <v-text-field v-model="$usuario.cadastro.end_complemento" type="text" dense outlined hide-details class="mt-3" tabindex="16"></v-text-field>
                                        </v-col>

                                    </v-row>
                                </v-col>
                            </template>

                            <v-col cols="12" class="py-0 text-left d-flex flex-column">
                                <span class="red--text" v-once>* {{ $t_('campos_obrigatorios') }}</span>
                                <span v-once>{{ $t_('preencha') }}</span>
                            </v-col>

                            <v-col cols="10 mt-4" class="d-flex justify-center align-center">
                                <v-checkbox v-model="$usuario.cadastro.termos" class="ma-0" tabindex="9"
                                    :error-messages="$usuario.cadastro.error.termos"
                                    @input="$usuario.cadastro.error.termos = []">
                                    <template v-slot:label>
                                        <div>
                                            {{ $t_('aceito_termos_1') }}
                                            <a target='_blank' style="text-decoration:underline" @click.stop="showPoliticas = true"> {{ $t_('politica_privacidade') }}</a>
                                        </div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-col cols="12">
                        <v-row align="center" justify="center">
                            <v-col cols="12" md="6" class="text-left d-flex align-center justify-center">
                                <div >
                                    <vue-recaptcha tabindex="10" theme="red" :size="$config.dados.recaptcha" @verify="$root.onCaptchaVerified" @expired="$root.onCaptchaExpired"
                                        :language="$axios.getLocal('lang').data"
                                        v-if="$usuario.showModalLogin && $config.conf && $config.conf.site_key_recaptcha && !$sac.recaptchaAtivo"
                                        class="vue-captcha" :sitekey="$config.conf.site_key_recaptcha"
                                        :loadRecaptchaScript="true"></vue-recaptcha>
                                </div>
                            </v-col>
                            <v-col cols="12" md="7" class="d-flex align-center justify-center text-center">
                                <v-btn x-large tabindex="11" class="mr-md-3" :loading="$usuario.cadastro.loading"
                                    :color="$config.dados.corprimaria"
                                    @click="$usuario.validarCadastro()"
                                    block elevation="0" style="color: white">
                                    <strong>{{ $t_('proximo') }}</strong>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-btn block text elevation="0" tabindex="12" @click="$usuario.showModalLogin_cadastro = false">
                            <span
                                class="mt-n2 text-decoration-underline font-weight-bold blue--text">{{$t_('voltar')}}</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text class="inputs-login" v-if="login.loginComum">
                <v-row>
                    <v-col cols="12">
                        <label class="text--disabled" v-once>{{$t_('email')}}</label>
                        <v-text-field v-model="login.email" type="text" dense outlined hide-details
                            :placeholder="$t_('ph_email')" tabindex="1"
                            v-on:keyup.enter="$usuario.getLogin(login.email, login.senha, login.item)"></v-text-field>
                    </v-col>
                    <v-col v-if="!login.esqueciSenha" cols="12">
                        <label class="text--disabled" v-once>{{$t_('senha')}}</label>
                        <v-text-field v-model="login.senha" hide-details dense placeholder="••••••••" outlined
                            :type="show1 ? 'text' : 'password'"
                            :append-icon="show1 ? 'mdi-eye mdi-color-gray' : 'mdi-eye-off mdi-color-gray'"
                            @click:append="show1 = !show1" tabindex="2"
                            v-on:keyup.enter="$usuario.getLogin(login.email, login.senha, login.item)"></v-text-field>
                    </v-col>
                    
                    <v-col cols="12">
                        <v-btn v-if="!login.esqueciSenha" elevation="0" :color="$config.dados.corprimaria"
                            @click="$usuario.getLogin(login.email, login.senha, login.item)" :loading="$usuario.cadastro.loading"
                            block style="color: white">
                            <strong v-once>{{ $t_('entrar') }}</strong>
                        </v-btn>
                        <vue-recaptcha
                            :size="$config.dados.recaptcha"  @verify="$root.onCaptchaVerified" @expired="$root.onCaptchaExpired"
                            :language="$axios.getLocal('lang').data"
                            v-if="$config.recaptchaModal && login.esqueciSenha && ($config.conf && $config.conf.site_key_recaptcha && $config.conf.recaptcha_ativo && $config.conf.recaptcha_ativo)"
                            class="vue-captcha mb-5" :sitekey="$config.conf.site_key_recaptcha"
                            :loadRecaptchaScript="true"></vue-recaptcha>
                        <v-btn v-if="login.esqueciSenha" elevation="0" :color="$config.dados.corprimaria" block
                            @click="$usuario.recuperarSenha(login.email)" :loading="$usuario.menu.loading" style="color: white">
                            <strong v-once>{{ $t_('recuperar') }}</strong>
                        </v-btn>
                        <a v-if="!login.esqueciSenha" class="text-decoration-underline d-flex justify-center mt-4"
                            style="color:#9e9e9e"
                            @click="login.esqueciSenha = true, $config.recaptchaModal = true">{{$t_('esqueci_senha')}}</a>
                        <a v-else class="text-decoration-underline d-flex justify-center mt-4" style="color:#9e9e9e"
                            @click="login.esqueciSenha = false">{{$t_('voltar')}}</a>
                        <a v-if="!login.esqueciSenha" class="text-decoration-underline d-flex justify-center mt-4"
                            style="color:#9e9e9e" @click="login.loginComum = false">{{$t_('voltar')}}</a>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <ModalTermos :showTermosSite="showTermosSite" @update-dialog="updateShowTermos"/>
        <ModalPoliticas :showPoliticas="showPoliticas" @update-dialog="updateShowPoliticas"/>
    </v-dialog>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import { mask } from "vue-the-mask";
    export default {
        name: 'ModalLogin',
        directives: { mask },
        components: {
            VueRecaptcha,
            VuePhoneNumberInput,
            ModalTermos: () => import('@/components/modal-termos.vue'),
            ModalPoliticas: () => import('@/components/modal-politicas.vue'),
        },
        data() {
            return {
                show1: false,
                show2: false,
                showLogin: false,
                login: {
                    email: '',
                    senha: '',
                    esqueciSenha: false,
                    loginComum: false,
                    loginAgencia: false,
                },
                placeholderData: 'dd/mm/aaaa',
                showTermosSite: false,
                showPoliticas: false,
                menu: false,
                dt_nascimento: null,
                activePicker: null,
                phoneInputLang: {
                    countrySelectorLabel: 'Country code',
                    countrySelectorError: 'Choose a country',
                    phoneNumberLabel: 'Phone number',
                    example: 'Example:'
                },
                errorNumber: false,
                pais: [],
                estados: [],
            }
        },
        watch: {
            'login.esqueciSenha'() {
                if (
                this.$config.conf.site_key_recaptcha &&
                this.$config.conf.recaptcha_ativo
            ) {
                const time = setInterval(() => {
                    if(window.grecaptcha){
                        clearInterval(time)
                        this.$config.dados.recaptcha == 'visible' ?  window.grecaptcha.reset() : window.grecaptcha.execute();
                    }
                }, 1000)
            }
            },
            '$usuario.showModalLogin'() {
                this.$usuario.showModalLogin_cadastro = false;
                this.$usuario.showModalLoginAgencia = false;
                this.$usuario.showModalLoginEsqueciSenha = false;
                this.$usuario.cadastro.nome = ''
                this.$usuario.cadastro.sobrenome = ''
                this.$usuario.cadastro.email = ''
                this.$usuario.cadastro.email2 = ''
                this.$usuario.cadastro.senha = ''
                this.$usuario.cadastro.senha2 = ''
                this.$usuario.cadastro.nacionalidade = ''
                this.$usuario.cadastro.dt_nascimento = ''
                this.login.loginAgencia = false;
            },
            '$usuario.showModalLoginEsqueciSenha'(){
                this.login.loginComum   = true
                this.login.esqueciSenha = true
            },
            '$usuario.showModalLoginAgencia'(){
                this.login.loginComum   = false;
                this.login.loginAgencia = true;
                this.login.showModalLogin = true;
                this.$usuario.showModalLogin_cadastro = true;
            },
            menu(val) {
                val && setTimeout(() => (this.activePicker = 'YEAR'))
            },
            '$usuario.cadastro.end_cep'(cep){
            // console.log(cep)
                if(cep && cep.length == 8){
                    this.$usuario.cadastro.end_pais = 'Brasil'
                    return this.$util.getCEP(cep)
                }
            },
            '$util.responseCEP'(e){
                // console.log(e)
                this.$usuario.cadastro.end_estado     = e.uf
                this.$usuario.cadastro.end_cidade     = e.localidade
                this.$usuario.cadastro.end_bairro     = e.bairro
                this.$usuario.cadastro.end_logradouro = e.logradouro
            },
        },
        methods: {
            updateCellPhoneNumber(data) {
                // DESCOBRE O CODIGO E O NUMERO DA MASCARA DE CADA PAIS
                // this.temp_.push({ code: this.$util.removerCaracteres(document.getElementsByClassName('country-selector__input')[0].value), mask: this.$util.removerCaracteres(document.getElementsByClassName('input-tel__label')[0].innerHTML).toString().length})
                // console.log(this.temp_)

                const mask = this.$util.celmask.find(item => item.code == data.countryCallingCode)
                
                if(data.nationalNumber){
                    setTimeout(() => {
                        const celular = data.nationalNumber.toString().substring(0, mask.mask);
                              document.getElementsByClassName('input-tel__input')[0].value = celular;
                        this.$usuario.cadastro.telefone              = celular
                        this.$usuario.cadastro.telefoneComCodigoPais = data.e164;
                    }, 10)
                }
            },
            updatePhoneNumber(data) {
                // DESCOBRE O CODIGO E O NUMERO DA MASCARA DE CADA PAIS
                // this.temp_.push({ code: this.$util.removerCaracteres(document.getElementsByClassName('country-selector__input')[0].value), mask: this.$util.removerCaracteres(document.getElementsByClassName('input-tel__label')[0].innerHTML).toString().length})
                // console.log(this.temp_)

                const mask = this.$util.celmask.find(item => item.code == data.countryCallingCode)
                
                if(data.nationalNumber){
                    setTimeout(() => {
                        const celular = data.nationalNumber.toString().substring(0, mask.mask);
                              document.getElementsByClassName('input-tel__input')[0].value = celular;
                        this.$usuario.cadastro.celular              = celular
                        this.$usuario.cadastro.celularComCodigoPais = data.e164;
                    }, 10)
                }

            },
            traducoesNumber(){
                let getPhoneNumber = this.$lang_.getLang()

                if(getPhoneNumber.lang === 'pt-BR') {
                    this.phoneInputLang = {
                        countrySelectorLabel: 'Código do país',
                        countrySelectorError: 'Escolha um país',
                        phoneNumberLabel: 'Número de telefone',
                        example: 'Exempĺo:'
                    }
                }

                if(getPhoneNumber.lang === 'es-ES') {
                    
                    this.phoneInputLang = {
                        countrySelectorLabel: 'Código do país',
                        countrySelectorError: 'Elige un pais',
                        phoneNumberLabel: 'Número de teléfono',
                        example: 'Ejemplo:'
                    }
                }

                if(getPhoneNumber.lang === 'en-US'){
                    this.placeholderData = 'mm/dd/yyyy'
                }
            },
            replaceSpecialChars(str){
                str = str.replace(/[ÀÁÂÃÄÅ]/,"A");
                str = str.replace(/[àáâãäå]/,"a");
                str = str.replace(/[ÈÉÊË]/,"E");
                str = str.replace(/[Ç]/,"C");
                str = str.replace(/[ç]/,"c");
                // o resto
                return str.replace(/[^a-z0-9]/gi,''); 
            },
            search(array, search){
                let pesquisa = this.replaceSpecialChars(search.toString().toLocaleLowerCase().normalize("NFD"));
                let palavra = this.replaceSpecialChars(array.dsc_lang.toString().toLocaleLowerCase().normalize("NFD"))

                if(palavra.indexOf(pesquisa) != -1){
                    return array;
                }
            },
            changeShow() {
                this.login.esqueciSenha = false
                this.login.loginComum = false
                this.$usuario.showModalLogin_cadastro = false
                this.$emit("update-login", false);
            },
            updateShowTermos(showDialog) {
                this.showTermosSite = showDialog
            },
            updateShowPoliticas(showDialog) {
                this.showPoliticas = showDialog
            },
            save(date) {
                this.$refs.menu.save(date)
                this.$usuario.cadastro.dt_nascimento = this.$moment(this.dt_nascimento).format('DD/MM/YYYY');
            },
        }, 
        computed: {
            chekingPassword() {
                return this.$usuario.validaSenha(this.$usuario.cadastro.senha)
            },
        },
        created() {
            this.$util.getPaises();
            this.traducoesNumber();
        }
    }
</script>

<style scoped>
@media screen and (max-width: 320px) {
    .txt-primeiro-acesso {
        font-size: 13px;
        margin-left: -1rem;
    }
    .txt-esqueci-senha {
        font-size: 14px;
        margin-left: -2.5rem;
    }
}
@media screen and (max-width: 380px) {
    .v-btn.v-size--default{
        font-size: 0px !important;
        /* padding-left: 0px !important; */
    }
}
    
    @media screen and (max-width: 480px) {
        .inputs-login{
            margin-top: 35%;
        }
    }

</style>